<template>
  <Component
    :is="as === 'button' ? 'button' : HeadlessComboboxOption"
    :class="{
      '!bg-primary text-white': selected
    }"
    class="relative flex cursor-pointer items-center justify-center gap-2 rounded border-2 border-[#537390] bg-white px-4 py-1.5 text-center capitalize shadow-md transition hover:scale-105 focus:outline-none"
  >
    <slot />
  </Component>
</template>

<script setup lang="ts">
import { HeadlessComboboxOption } from '#components'

interface Props {
  as?: 'button' | 'ComboboxOption'
  selected?: boolean
}

// this rule is deprecated
// eslint-disable-next-line vue/no-setup-props-destructure
const { as = 'ComboboxOption' } = defineProps<Props>()
</script>
